import { IPagination } from './common';

export interface IPlatform {
  id: number;
  name: string;
  numberDisplayFormat: INumberDisplayFormat;
  // Deprecated
  code?: string;
}

export type IGetPlatformConfigParams =
  | {
      platformId: IPlatform['id'];
    }
  | {
      sellerId: ISeller['id'];
    };

export interface INumberDisplayFormat {
  thousandSeparator: string;
  decimalSeparator: string;
}

export interface IGetAllPlatformsResponse {
  platforms: IPlatform[];
}

export interface ISeller {
  districtName: string | null;
  wardName: string | null;
  provinceCode: string;
  id: number;
  code: string;
  name: string;
  servicePackage: string;
  wardCode: string;
  email: string | null;
  saleCategoryIDS: number[];
  fullAddress: string;
  internationalName: string;
  displayName: string;
  logoUrl: string;
  phoneNumber: string;
  taxIDNumber: string;
  isAutoGeneratedSKU: number;
  brcCode: string;
  slogan: string | null;
  isActive: number;
  usingGoodsManagementModules: number;
  districtCode: string;
  streetAddress: string;
  foundingDate: string;
  provinceName: string | null;
  brcDate: string;
}

export interface IGetAllSellersParams {
  platformIds?: string;
}

export interface IGetAllSellersResponse {
  sellers: ISeller[];
}

export interface Website {
  domain: string;
  tempiWebsiteId: number | null;
  type: 'APP' | 'WEB';
}

export interface GetListPlatformParams extends IPagination {
  platformId?: IPlatform['id'];
  query?: string;
}

export interface GetListPlatformResponse extends IPagination {
  platforms: IPlatform[];
  total: number;
}

export enum GetSellerListExtraEnum {
  ExcludeFilterCreatedBySeller = 'excludeFilterCreatedBySeller',
}

export interface IGetSellerListParams extends Partial<IPagination> {
  ids?: string;
  platformIds?: string;
  extra?: GetSellerListExtraEnum;
}

export interface IGetSellerListResponse extends IPagination {
  sellers: ISeller[];
  total: number;
}
