import React, { useState, useMemo } from 'react';
import { Modal, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { IUserDetailsInfo } from 'teko-oauth2';
import { t } from 'helpers/i18n';
import { sellerHelpers } from 'helpers';
import { IPlatform, ISeller } from 'interfaces';
import SellerItem from 'components/Seller/SellerItem';
import { LogoutButton } from 'components/shared/Button';
import ContactAdminMessage from 'components/shared/ContactAdminMessage';
import EmptyBlock from 'components/shared/EmptyBlock';
import { commonConstants } from 'constants/index';
import './SellerPopup.scss';

interface SellerPopupProps {
  platformInfo: IPlatform | undefined;
  authorizedSellers: ISeller[];
  currentUser: IUserDetailsInfo;
}

const { getSearchSellers } = sellerHelpers;
const {
  VNLIFE_DOMAINS,
  DEFAULT_SUPPORT_EMAIL,
  VNLIFE_SUPPORT_EMAIL,
} = commonConstants;

const SellerPopup: React.FC<SellerPopupProps> = props => {
  const { platformInfo, authorizedSellers, currentUser } = props;
  const [keyword, setKeyword] = useState('');

  const userIdentity =
    currentUser.email || currentUser.phone_number || currentUser.name;

  const { origin, hostname } = window.location;
  const emailSupport = VNLIFE_DOMAINS.some(domain => domain === hostname)
    ? VNLIFE_SUPPORT_EMAIL
    : DEFAULT_SUPPORT_EMAIL;

  const results = useMemo(() => {
    return getSearchSellers(authorizedSellers, keyword);
  }, [keyword, authorizedSellers]);

  return (
    <Modal
      wrapClassName="seller-popup"
      title={t('SellerInformation')}
      bodyStyle={{ padding: '8px', maxHeight: 380, overflowY: 'auto' }}
      visible
      closable={false}
      footer={<LogoutButton />}
    >
      <div className="px-base py-half">
        {!!authorizedSellers.length ? (
          <>
            <div className="seller-popup-search">
              {platformInfo ? (
                <>
                  {t('PleaseChooseSellerSellOnPlatform')}:
                  <span className="ml-half text-bold">{platformInfo.name}</span>
                </>
              ) : (
                t('PleaseChooseSellerToContinue')
              )}
              <Input
                className="my-half"
                autoFocus
                allowClear
                placeholder={t('SearchSellerPlaceholder')}
                prefix={<SearchOutlined />}
                onChange={e => setKeyword(e.target.value.trim())}
              />
            </div>
            <div className="seller-popup-list">
              {!!results.length ? (
                results.map(seller => (
                  <SellerItem key={seller.id} seller={seller} />
                ))
              ) : (
                <EmptyBlock className="m-0 pt-base" />
              )}
            </div>
          </>
        ) : (
          <>
            {t('TheAccount')}
            <span className="mx-half text-primary text-medium">
              {userIdentity}
            </span>
            {t('HasNotBeenAssignedAnyValidSellersOn')}
            <b>{origin}</b>.
            <br />
            <ContactAdminMessage email={emailSupport} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default SellerPopup;
