import { commonConstants, flagConstants } from 'constants/index';
import userHelpers from 'helpers/user';
import {
  GetSellerListExtraEnum,
  IFlagsData,
  IGetPlatformConfigParams,
  IPlatform,
  ISeller,
  Website,
} from 'interfaces';
import { get, parseInt } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { sellerServices, staffServices } from 'services';
import { IUserDetailsInfo } from 'teko-oauth2';

const {
  getCurrentPlatform,
  setCurrentPlatform,
  getCurrentSeller,
  setCurrentSeller,
} = userHelpers;

const { DEFAULT_NUMBER_DISPLAY_FORMAT } = commonConstants;

const useValidatedPlatformInfo = (currentUser?: IUserDetailsInfo) => {
  const [isFetching, setIsFetching] = useState(true);
  const [platformInfo, setPlatformInfo] = useState<IPlatform>();
  const [isAssignedWithAllPlatforms, setIsAssignedWithAllPlatforms] = useState(
    false
  );

  const savedPlatformId = getCurrentPlatform();

  const validatePlatform = async (
    platformId?: string
  ): Promise<IPlatform | undefined> => {
    const platformIdInt = parseInt(platformId || '');
    if (!platformIdInt) return undefined;

    try {
      const resp = await staffServices.getPlatformConfig({
        platformId: platformIdInt,
      });
      return resp;
    } catch (error) {
      return undefined;
    }
  };

  const updatePlatformInfo = useCallback(
    (platform?: IPlatform) => {
      if (platform) {
        setPlatformInfo(platform);
        setCurrentPlatform(platform.id);
      }
    },
    [setPlatformInfo, setCurrentPlatform]
  );

  useEffect(() => {
    // If no current user, do nothing
    if (!currentUser) return;
    // Fall back to -1 if user has not assigned
    const assignedPlatformId = String(
      get(currentUser, ['meta_data', 'platformId'], -1)
    );

    (async () => {
      try {
        // When user has not assigned any platformId
        // Do not save any platformId and info
        if (assignedPlatformId === '-1') {
          updatePlatformInfo(undefined);
          return;
        }

        // When user has assigned ALL platforms (0)
        // Check if there is any saved platformId in the local storage, if yes validate it (similar to the above case)
        // If not, do not save any platformId (let user select manually)
        if (assignedPlatformId === '0') {
          setIsAssignedWithAllPlatforms(true);

          const platform = await validatePlatform(savedPlatformId);
          updatePlatformInfo(platform);
          return;
        }

        // When user has assigned SPECIFIC platform => validate if the ID is valid
        if (assignedPlatformId && assignedPlatformId !== '0') {
          // Call API to get the platform info
          // If the platformId is valid, update the platform info and saved it
          const platform = await validatePlatform(assignedPlatformId);
          updatePlatformInfo(platform);
          return;
        }
      } finally {
        setIsFetching(false);
      }
    })();
  }, [currentUser]);

  return {
    isFetching,
    platformInfo,
    isAssignedWithAllPlatforms,
  };
};

const useWebsites = ({
  currentUser,
  featureFlagsData,
}: {
  currentUser?: IUserDetailsInfo;
  featureFlagsData: IFlagsData;
}) => {
  const [websites, setWebsites] = useState<Website[]>([]);
  const [fetchingWebsites, setFetchingWebsites] = useState<boolean>(false);
  const isEcom474Enabled =
    featureFlagsData[flagConstants.FLAG_KEYS.INTEGRATE_TEMPI_WEBSITE_BUILDER]
      ?.enabled;

  useEffect(() => {
    const userPlatformId = currentUser?.meta_data.platformId;
    const isMerchantUser = !!currentUser?.meta_data.merchant_id;

    if (isEcom474Enabled && isMerchantUser && userPlatformId) {
      setFetchingWebsites(true);
      staffServices
        .getWebsitesByPlatformId(userPlatformId)
        .then(value => setWebsites(value))
        .finally(() => {
          setFetchingWebsites(false);
        });
    }
  }, [currentUser, isEcom474Enabled]);

  return {
    websites,
    fetchingWebsites,
  };
};

const useValidatedSellerInfo = (
  isFetchingPlatforms: boolean,
  platformInfo: IPlatform | undefined,
  currentUser: IUserDetailsInfo | undefined,
  enableFlagToGetPlatformConfig?: boolean
) => {
  const [sellerInfo, setSellerInfo] = useState<ISeller>({} as ISeller);
  const [platformConfigBySeller, setPlatformConfigBySeller] = useState<
    IPlatform
  >({
    id: 0,
    name: '',
    numberDisplayFormat: DEFAULT_NUMBER_DISPLAY_FORMAT,
  });
  const savedSeller = getCurrentSeller();
  const { isFetching: isFetchingSellers, authorizedSellers } = useAvailSellers(
    isFetchingPlatforms,
    currentUser,
    platformInfo?.id
  );

  const validateSeller = useCallback(
    (sellerId?: string) => {
      if (!sellerId || !authorizedSellers.length) return;
      // Check if sellerId is valid and exists in availSellers
      const seller = authorizedSellers.find(
        seller => seller.id === parseInt(sellerId)
      );
      if (seller) {
        setSellerInfo(seller);
        setCurrentSeller(seller.id);
      }
    },
    [authorizedSellers, setSellerInfo, setCurrentSeller]
  );

  useEffect(() => {
    if (!currentUser) return;

    const assignedSellerIds = `${get(
      currentUser,
      ['meta_data', 'seller_id'],
      ''
    )}`
      .trim()
      .split(',')
      .filter(string => string !== '');

    // If user assigned with single, specific seller id, validate that seller
    if (assignedSellerIds.length === 1 && assignedSellerIds[0] !== '0') {
      validateSeller(assignedSellerIds[0]);
      return;
    }

    if (assignedSellerIds[0] === '0' || assignedSellerIds.length > 1) {
      validateSeller(savedSeller);
      return;
    }

    if (assignedSellerIds.length === 0) {
      return;
    }
  }, [currentUser, authorizedSellers]);

  const getPlatformConfigBySeller = async (
    params: IGetPlatformConfigParams
  ) => {
    try {
      const platformConfig = await staffServices.getPlatformConfig(params);
      if (platformConfig?.numberDisplayFormat?.thousandSeparator) {
        setPlatformConfigBySeller(platformConfig);
      }
    } catch {}
  };

  useEffect(() => {
    if (!!sellerInfo.id && enableFlagToGetPlatformConfig) {
      getPlatformConfigBySeller({ sellerId: sellerInfo.id });
    }
  }, [sellerInfo?.id]);

  return {
    isFetchingSellers,
    authorizedSellers,
    sellerInfo,
    platformConfigBySeller,
  };
};

const useAvailSellers = (
  fetchingPlatforms: boolean,
  currentUser?: IUserDetailsInfo,
  platformId?: IPlatform['id']
) => {
  const [isFetching, setIsFetching] = useState(false);
  const [authorizedSellers, setAuthorizedSellers] = useState<ISeller[]>([]);

  useEffect(() => {
    if (fetchingPlatforms || !currentUser) return;

    const getAvailSellers = async () => {
      try {
        const assignedSellerIds = `${get(
          currentUser,
          ['meta_data', 'seller_id'],
          ''
        )}`
          .trim()
          .split(',')
          .filter(string => string !== '');
        const isAllSeller = assignedSellerIds?.[0] === '0';
        setIsFetching(true);
        const sellers = await sellerServices.getAllSellersList(
          {
            platformIds: platformId ? `${platformId}` : undefined,
            ids: isAllSeller ? undefined : assignedSellerIds.join(','),
            extra: GetSellerListExtraEnum.ExcludeFilterCreatedBySeller,
          },
          assignedSellerIds?.[0] || '0'
        );

        if (!sellers.length) return;

        setAuthorizedSellers(sellers);
      } catch {
        setAuthorizedSellers([]);
      } finally {
        setIsFetching(false);
      }
    };
    getAvailSellers();
  }, [fetchingPlatforms, currentUser, platformId]);

  return { isFetching, authorizedSellers };
};

export default {
  useWebsites,
  useValidatedPlatformInfo,
  useValidatedSellerInfo,
};
