import {
  IGetAllSellersParams,
  IGetAllSellersResponse,
  GetListPlatformParams,
  GetListPlatformResponse,
  IGetSellerListParams,
  IGetSellerListResponse,
  ISeller,
} from 'interfaces';
import { requestServices } from 'services';
import { getResult, getData } from 'helpers/request';

const { sellerClient, staffClient } = requestServices;

const getListPlatforms = async (
  params: GetListPlatformParams
): Promise<GetListPlatformResponse> => {
  return staffClient
    .get('/api/v1/platforms', {
      params,
    })
    .then(getResult);
};

const getAllSellers = (
  params: IGetAllSellersParams
): Promise<IGetAllSellersResponse> => {
  return sellerClient
    .get('/sellers/get-all', {
      params,
    })
    .then(getResult);
};

const getSellerList = (
  params: IGetSellerListParams,
  sellerId: string
): Promise<IGetSellerListResponse> => {
  return staffClient
    .get('/api/v1/seller-list', {
      params,
      headers: {
        'X-Seller-Id': sellerId,
      },
    })
    .then(getData);
};

const getAllSellersList = async (
  params: IGetSellerListParams,
  sellerId: string
): Promise<ISeller[]> => {
  const MAX_LOOP_COUNT = 10;
  const MAX_PAGE_SIZE = 200;

  let currentPage = 1;
  let allSellers: ISeller[] = [];

  let loopCount = 0; // hardcode to prevent loop too many times

  while (true) {
    if (loopCount >= MAX_LOOP_COUNT) {
      break;
    }

    const response = await getSellerList(
      {
        ...params,
        pageSize: MAX_PAGE_SIZE,
        page: currentPage,
      },
      sellerId
    );

    allSellers = allSellers.concat(response.sellers);

    if (allSellers.length >= response.total) {
      break;
    }

    currentPage++;
    loopCount++;
  }

  return allSellers;
};

export default {
  getAllSellers,
  getListPlatforms,
  getSellerList,
  getAllSellersList,
};
